/* Loader.css */
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
  }
  
  .loader-svg {
    width: 100%;
    height: 100%;
    animation: rotate 2s linear infinite;
  }
  
  .loader-circle {
    fill: none;
    stroke: url(#gradient);
    stroke-width: 6;
    stroke-dasharray: 283;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 283;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 150, 283;
      stroke-dashoffset: -75;
    }
    100% {
      stroke-dasharray: 283, 283;
      stroke-dashoffset: -260;
    }
  }
  