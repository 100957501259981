.profile-content{
    width: 100%;
}
.profiledata{
    background: var(--bg-grey);
    padding:24px 100px;
    display: flex;
    .advisorcard{
        display: flex;
        column-gap: 24px;
        flex: 1;
        .username{
            display: flex;
            flex-direction: column;
            row-gap: 16px;
        }
        h4{
            margin: 0;
            font-size: 24px;
            color: var(--b-primary);
        }
        .designations{
            display: flex;
            column-gap: 24px;
            flex-wrap: wrap;
            a{
                display: flex;
                align-items: center;
                column-gap: 8px;
                span{
                    color: var(--f-navyblue);
                }
            }
        }
    }
}
.profileaction{
    display: flex;
    column-gap: 24px;
}
.profileoption{
    display: flex;
    padding: 32px 100px;
    flex-direction: column;
    row-gap: 48px;
    .divider{
        border-bottom: 1px var(--border) solid;
        width: 100%;
    }
}
.plan{
    background: var(--white);
    box-shadow: 0 8px 28px rgba(24,39,75,.12) , 0 18px 88px rgba(24,39,75,.14);
    padding: 16px 16px 16px 32px;
    display: flex;
    width: 100%;
    border-radius: 8px;
    align-items: center;
    .planinfo{
        display: flex;
        align-items: center;
        column-gap: 48px;
        row-gap: 8px;
        flex: 1;
        flex-wrap: wrap;

        h4{
            font-size: 20px;
            color: var(--b-primary);
            margin: 0;
        }
        .point{
            display: flex;
            align-items: center;
            column-gap: 8px;
            &:before{
                content: "";
                background: var(--border);
                display: inline-block;
                width: 8px;
                height: 8px;
                margin-right: 40px;
                border-radius: 100%;
            }
        }
    }
}
.history{
    display: flex;
    h4{
        display: flex;
        flex: 1;
        font-size: 20px;
        font-weight: normal;
        margin: 0;
    }
    .closeacc{
        display: flex;
        flex: 1;
        flex-direction: column;
        row-gap: 16px;
        h4{
            color: var(--Error);
        }
    }
}
