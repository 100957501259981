.errorField {
    display: flex;
    align-items: center;
    padding: 10px;
    /* border: 1px solid red;
    background-color: #ffeeee; */
}

.warningSign {
    margin-right: 10px;
    color: yellow;
}

.errorText {
    color: red;
}
