
.riskhead {
    display: flex;
    flex-wrap: wrap;
    padding:8px 16px;
    width: 100%;
    column-gap: 24px;
    background: var(--bg-primary);
    align-items: center;
  
    .riskstate {
      display: flex;
      align-items: center;
      gap: 8px;
      h3 {
        color: var(--b-secondary);
        font-size: 24px;
        margin: 0;
      }
  
      .lblrisk {
        display: flex;
        align-items: center;
        div {
          display: inline-block;
          vertical-align: middle;
          margin-left: 8px;
        }
      }
    }
  
    h2 {
      color: var(--b-primary);
      font-size: 18px;
      line-height: 24px;
      display: flex;
      flex: 1;
      font-weight: 600;
      margin: 0;
    }
  }
  
  .riskmodes {
    display: flex;
    column-gap: 24px;
    flex-wrap: wrap;
    margin-bottom: 16px;
  
    div {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  
    span {
      display: inline-block;
    }
  
    .risk {
      width: 16px;
      height: 16px;
      background: var(--b-primary);
    }
  
    .position {
      width: 16px;
      height: 16px;
      background: var(--b-secondary);
      border-radius: 16px;
    }
  
    .vix {
      width: 32px;
      height: 4px;
      background: var(--Blue);
    }
  }



.beta-v {
  display: inline-block;
  padding: 2px 8px;
  color: white;
  background-color: var(--b-secondary);
  line-height: 1;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
  line-height: 12px;
}

