.client-portfolio {
    padding: 20px;
    width: 100%;
    margin: 0 auto;
    &.center{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;
    }
}

.client-portfolio h2 {
    margin: 15px 0;
    color: #333;
}

.client-portfolio label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.client-portfolio input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.client-portfolio .portfolio-section {
    margin: 20px 0;
}

.client-portfolio .line-item-section {
    margin-top: 10px;
    padding-left: 20px;
}

.portfoliocard{
    background: var(--white);    
    border-radius: 8px;
    margin-bottom: 24px;
}
.portfolioheader{
    display: flex;
    flex-direction: row;
    width: 100%;
    column-gap: 32px;
    margin-bottom: 16px;
    .input{
        width: 100%;
        input{
            font-size: 16px;
            padding: 16px !important;
            width: 100%;
            height: auto;
            margin: 0;
        }
    }
    button{
        width: 50px;
        height: 50px;
        background: none;
        color: var(--white);
        border: none;
        border-radius: 4px;
        img{
            width: 30px;
        }
    }
}