/* DataTable.module.css */
.tableResponsive {
    overflow-x: auto;
    overflow-y: auto;
    max-height: 550px;
    font-family: 'Inter', sans-serif;
}

.table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

.table th, .table td {
    padding: 8px;
    border: 1px solid #DFE4E9;
    font-size: 14px; /* Adjusted font size */
    color: var(--clr-3); /* Using your color variables */
    position: sticky;
}


.table thead th {
    background-color: #F2F4F6;
    color: var(--color-1);
    font-weight: var(--fw-500);
    text-transform: uppercase;
    /* position: sticky; */
    top: 0; /* Stick to the top of the table */
    z-index: 3; /* Ensure it stays above other content */
}


/* Style for the inner headers (second row of headers) */
.table thead tr:nth-child(2) th {
    background-color: #FFF; /* White background for inner headers */
    color: var(--color-2); /* Black color for the text */
    font-weight: var(--fw-500);
    position: sticky;
    top: 30px;
}


.textSmall {
    font-size: 0.8em;
    color: #666;
}

.currentPriceRow {
    background-color: #e0e0e0; /* Highlight for current price row */
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .tableResponsive {
        overflow-x: scroll;
    }
    .table {
        min-width: 1200px;
    }
}

@media (max-width: 768px) {
    .table th, .table td {
        font-size: 12px; /* Smaller font size for mobile */
    }
}

