/* Dashboard Component Styles */

/* Variable Definitions */
:root {
    --color-1: #60034C;
    --color-2: #000000;
    --clr-3: #8A92A6;
    --ex-clr: #E58B76;
    --fw-400: 400;
    --fw-500: 500;
    --fw-600: 600;
}

/* Dashboard Specific Styles */
.dashboard-sec {
    padding: 0px 0px 0 10px;
}

.dashboard-top {
    display: flex;
    border-bottom: 1px solid #DFE4E9;
}

.dash-list li label, .dash-list li p {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    /* Adjusts space between label text and input box */
}
.dash-list li p {
    margin: 0;
}


.dash-list li h3 {
    color: var(--ex-clr);
    font-size: 20px;
    font-weight: var(--fw-600);
}

.dash-list li {
    list-style: none;
    color: var(--color-2);
}

.txt-clr {
    color: var(--color-1);
    font-weight: 600;
    font-size: 14px;
}

.table.table.table-striped {
    width: 100%;
    border-collapse: separate
}

.callwriting select,.callwriting input {
    background: #f1f1f1;
    border-radius: 4px;
    border: none;
    padding: 8px 5px;
    color: var(--Black);
    box-sizing: border-box;
    border: none !important;
    width: 100px;
}
.callwriting select,.callwriting input, .smalldate .react-datepicker-wrapper input{
    border: 1px #ccc solid !important;
}

.OptionButton {
    border-radius: 4px;
    background: #434E6C;
    color: #ffffff;
    padding: 8px;
    border: 1px solid transparent;
    transition: all .48s ease-in-out;
    cursor: pointer;
    text-transform: capitalize;
    white-space: nowrap;
}

td button:hover {
    background-color: #ffffff;
    color: var(--color-1);
    border: 1px solid var(--color-1);
}

td:last-child,
th:last-child {
    text-align: right;
}

.cart-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
}

/* .cart-btn a {
    padding: 8px 16px;
    border-radius: 24px;
    border: 1px solid var(--color-1);
    text-decoration: none;
    color: var(--color-1);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;
    transition: all .48s linear;
} */

/* .cart-btn a:hover {
    transform: translateY(-5px);
} */

/* Responsive Styles */
@media (max-width: 1440px) {
    .dashboard-sec {
        padding: 32px 32px 0 32px;
    }

    .dash-list {
        column-gap: 24px;
    }
}

@media (max-width: 1200px) {
    .table-responsive {
        overflow: scroll;
    }

    table.table.table-striped {
        width: 1200px;
    }
}

@media (max-width: 768px) {
    .dash-list li {
        padding: 8px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        border-radius: 4px;
        height: 48px;
        display: flex;
        align-items: center;
    }
}

@media (max-width: 570px) {
    .dash-list li {
        width: 100%;
    }
}


.dashboard-sec {
    padding: 10px 10px 0 10px;
    font-family: 'Inter', sans-serif;
}

.dashboard-top {
    display: flex;
    border-bottom: 1px solid #DFE4E9;
}
.dash-list {
    display: flex;
    align-items: center;
    column-gap: 24px;
    row-gap: 8px;
    flex-wrap: wrap;
    font-weight: var(--fw-400);
    color: var(--clr-3);
    padding: 0 16px;
}
.dash-list .ai-form-group{flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;
}
.dash-list .ai-form-group input, .dash-list .ai-form-group select{width: 150px;margin: 0;}

.dash-list li h4 {
    color: var(--ex-clr);
    font-size: 16px;
    font-weight: var(--fw-600);
    margin: 0;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dot-flashing {
    position: relative;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: #333;
    color: #333;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
}

.dot-flashing::before,
.dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: var(--color-1);
    color: var(--color-1);
}

.dot-flashing::before {
    left: -1.5em;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 1.5em;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
        background-color: var(--color-1);
    }

    50%,
    100% {
        background-color: #fff;
    }
}


.checkbox-column {
    width: 10px;
    /* Adjust if necessary to match the size of the checkbox */
    padding: 0;
    /* Remove padding if any */
    text-align: center;
    /* To keep the checkbox centered */
}

input[type=checkbox] {
    width: 16px;
    padding: 0;
    accent-color: var(--color-1);
    height: 16px;
}

/* table input[type="number"]{
    width: 50px;
    height: 30px;
    text-align: center;
    padding: 0; 
} */
.selector{
    display: flex;
    align-items: center;
}
.callwriting th{white-space: nowrap;}

.reload_icon {
    height: 12px;
    width: 12px;
    margin-right: 10px;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(35deg) brightness(101%) contrast(101%);;
}
.callwriting td{border-top: 1px solid #f5f5f5;}
.opentiker{
    background: #fafafa;
    &.selected{
        background: var(--Blue);
        background:#718189;
        td{
            color: var(--white);
        }
    }
}
.opentiker.subitems td{background: #f5f5f5;}
.ticker-header-row {
    td{
        border-top: 2px solid var(--f-navyblue);
        // background: #f5f5f5;
        color: var(--b-secondary);
    }
}
.selected-row {
    td{
        // background: #c8e8fa;
        background: #eff1f2;
    }
}
.collapser{
    cursor: pointer;
}
.button-container {
    display: flex;
    gap: 24px;
}

.small-loader .loader-svg {
    width: 10px; /* Adjust the size as needed */
    height: 10px;
  }

  
button.icon-button{
    width: 30px;
    height: 30px;
    border: none;
    padding: 4px;
    border-radius: 4px;
    align-self: center;
    // background: none;
    img.button-icon {
        width: 100%;
    }
    &.add { 
        // background: var(--b-primary);
    }
    &.roll { 
        // background: var(--Blue);
    }
    
    &.close{
        // background: var(--Error);
    }
    &.small{
        width: 24px;
        height: 24px;
        color: var(--white);
        font-size: 18px;
        line-height: 10px;
    }
    &.collapse{
        background: var(--f-navyblue);
        border-radius: 100%;
    }
}

.disabled-row {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
    color: #999;
  }
  