.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background-color: white;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 1px var(--border) solid;
    z-index: 1;
    position: relative;

}

.logo {
    font-size: 24px;
    flex-shrink: 0;
}

.navbar-center {
    list-style: none;
    display: flex;
    gap: 30px;
    flex-grow: 1;
    margin: 0;
    justify-content: center;

}

.navbar-center {
    li {
        cursor: pointer;
        display: flex;
        align-items: center;

        a {
            text-decoration: none;
            color: var(--Black);
            padding: 10px 16px;
            border-radius: 4px;
            &:hover{
                background: var(--bg-grey);
            }
        }
    }
}

.dashboard {
    color: #888;
}

.client-portfolio-btn {
    background-color: var(--b-primary);
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: inline-flex;
    align-items: center;
}

.client-portfolio-btn:hover {
    background-color: var(--b-primary);
}

.navbar-right {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
}

.search-bar {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
}

.inputs input[type="text"],
.inputs input[type="number"],
.addticker input[type="text"],
.addticker input[type="number"] {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-icon {
    color: #888;
}

.user-info {
    display: flex;
    align-items: center;
    gap: 10px;

    .user-avatar {
        width: 40px;
        height: 40px;
        background-color: var(--b-primary);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    .user-detail {
        display: flex;
        flex-direction: column;

        .user-name,
        .user-role {
            margin: 0;
            text-transform: capitalize;
        }

        .user-role {
            font-weight: 500;
            color: var(--b-secondary);
        }
    }
}

.menu-icon {
    display: none;
}

.menu-item {
    padding: 10px 36px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: inline-flex;
    align-items: center;
    color: #888;
}

.menu-item.active {
    background-color: var(--b-primary);
    color: white;
}

.menu-item:hover {
    background-color: var(--b-primary);
    color: white;
}

.toggle {
    display: inline-block;
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 16px;
    gap: 8px;

    span {
        background: var(--Black);
        width: 32px;
        height: 2px;
        display: inline-block;
    }
}

@media (max-width: 768px) {
    .navbar {
        // flex-direction: column;
        justify-content: space-between;
    }

    .navbar-center {
        flex-direction: column;
        gap: 10px;
        display: none;
        width: 100%;
    }

    .navbar-center.active {
        display: flex;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
    }

    // .navbar-right {
    //     width: 100%;
    //     flex-direction: column;
    //     align-items: flex-start;
    //     gap: 10px;
    // }

    .search-bar {
        width: 100%;
    }
}

.user-modal {
    position: absolute;
    background-color: #fff;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 10;
    transition: transform 0.3s;
    transform-origin: top left;
    width: 180px;
    right: 16px;
    top: 64px;
    border: none;
}

.modal-item {
    padding: 10px;
    cursor: pointer;
    // border-bottom: 1px solid #f0f0f0;
    display: flex;
    /* Added for alignment */
    align-items: center;
    /* Aligns items vertically center */
    gap: 10px;
    /* Gap between image and text */
    font-size: 16px;
    /* Increased font size */
}

.modal-icon {
    width: 24px;
    /* Adjust width and height based on your requirement */
    height: 24px;
}

.modal-item:hover {
    background-color: #f5f5f5;
}

/* Remove the border-bottom from the last modal item */
.modal-item:last-child {
    border-bottom: none;
}