.forwardtest-holder {
  width: 100%;
  background-color: var(--white);
  row-gap: 32px;
  display: flex;
  flex-direction: column;

  @media(max-width:992px) {
    height: auto;
  }

  .shieldstates {
    padding: 0 16px;

    .contentshield {
      width: 100%;

      .subnavbar-menu {
        width: 100%;
        padding: 0 16px;
      }
    }
  }
}

.shield-row {
  display: flex;
  column-gap: 48px;
  row-gap: 24px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.ai-shield-calculator-container {
  background-color: var(--white);
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  column-gap: 24px;
  row-gap: 24px;
}

.ai-shield-calculator-container * {
  box-sizing: border-box;
}

.ai-shield-calculator-container .ai-shield-calculator-select select {
  appearance: none;
  border: none;
  padding: 0 28px 4px 0;
  font-size: 20px;
  color: var(--b-secondary);
  position: relative;
  border-radius: 0;
  border-bottom: 2px solid var(--b-secondary);
  background: var(--white);
  width: 100%;
  font-weight: 700;
  margin: 8px 0;
}

.ai-shield-calculator-container .ai-shield-calculator-select select:focus {
  outline: none;
}

.ai-shield-calculator-container .ai-shield-calculator-select select option {
  font-size: 16px;
  color: var(--f-navyblue);
}

.ai-shield-calculator-container .ai-shield-calculator-select .ai-shield-calculator-select-arrow {
  position: absolute;
  top: 30px;
  right: 0px;
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("../../images/arrowdown.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.forwardtest {
  column-gap: 32px;
  row-gap: 48px;
  flex-wrap: wrap;
  width: 100%;

  .forgraphs {
    width: calc(100% - 24px);
    row-gap: 32px;
    display: flex;
    flex-direction: column;

    @media(max-width:992px) {
      width: 100%;
    }

    h3 {
      font-size: 20px;
      color: var(--f-navyblue);
      align-items: center;
      display: flex;
      // margin: 16px 0;
      column-gap: 8px;

      img {
        max-width: 20px;
      }
    }

    .forward-column {
      display: flex;
      flex-direction: column;
    }
  }
}

.forwardanalyze-portfolio {
  width: calc(100% - 24px);

  @media(max-width:992px) {
    width: 100%;
  }

  .barchart {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    margin-top: 24px;

    img {
      width: 100%;
    }
  }
}

.infomessage {
  margin-top: 0;
}

.dataforwardtest {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  row-gap: 32px;

  @media(max-width:992px) {
    flex: auto;
  }
}

.forwardrisk {
  display: flex;
  column-gap: 48px;
  justify-content: flex-end;
  padding: 0 24px;
  
}