/* AdvisorClientSummaryScreen.css */

.container {
    display: flex;
    /* height: 100vh; */
}

.sidebar {
    /* width: 250px;  adjust as needed */
    /* border-right: 1px solid #e1e1e1; */
    /* height: 100vh; */
    /* overflow-y: auto; */
}

.main-content {
    flex: 1;  /* takes the remaining width */
    /* padding: 10px; space around table */
}
