:root {
    --color-1: #60034C;
    --color-2: #000000;
    --clr-3: #8A92A6;
    --ex-clr: #E58B76;
    --fw-400: 400;
    --fw-500: 500;
    --fw-600: 600;
}


.global-control-container {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 20px 20px 20px;
    font-family: 'Inter', sans-serif;
}

.status-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

#global-expiry {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    max-width: 100px;
}

.portfolio-holdings {
    color: var(--color-1);
    font-weight: bold;
}

.portfolio-value {
    color: var(--color-1);
    font-weight: bold;
}

.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-left: 20px;
}

.table-container {
    display: flex;
    justify-content: flex-start;
    padding: 20px;
    width: 100%;
}

.vertical-line {
    width: 1px;
    background-color: #ccc;
    height: auto;
    /* Adjust the height as needed */
    align-self: stretch;
    /* Make sure the line spans the full height */
}

.symbol-table,
.options-table {
    width: auto;
    margin: 0;
    border-collapse: collapse;
    flex: 1;
}

.symbol-table th,
.symbol-table td,
.options-table th,
.options-table td {
    padding: 8px;
    text-align: left;
    background-color: #ffffff;
}

.symbol-table th,
.options-table th {
    color: var(--color-1);
}

.symbol-table tr,
.options-table tr {
    font-family: 'Inter', sans-serif;
}


.con-input input,
.cap-input input{
    width: 100%;
    padding: 4px;
    box-sizing: border-box;
    height: 30px;
}

.cal-input input{
    width: 90%;
    padding: 4px;
    box-sizing: border-box;
    height: 30px;

}

.stddev-select {
    width: 100%;
    border-radius: 5px;
    border: none;
    height: 30px;
    box-sizing: border-box;

}

th label {
    margin-right: 10px;
    /* Adjust the margin as needed */
}

th input {
    width: 90px;
}

.table-container .options-table tr.last-row td,
.table-container .symbol-table tr.last-row td {
    background-color: rgba(138, 146, 166, 0.1) !important;
    /* Force the background color on the last row */
}

.options-table .total-row td {
    width: 100%;
    background-color: #e58a7632;
}

.vertical-line {
    width: 1px;
    background-color: #ccc;
    height: auto;
    /* Adjust the height as needed */
    align-self: stretch;
    /* Make sure the line spans the full height */
}

.options-table .EmptyRow td {
    height: 20px;
}
.switcher{
    display: flex;
    align-items: center;
    gap: 16px;
    background: var(--Primary-Bg);
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
}
.toggle-switch {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 24px;
}

.toggle-switch input {
    opacity: 0;
    width: 100%;
    height: 100%;
}

.toggle-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--ex-clr);
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggle-label::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 26px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 10px;
    transition: transform 0.3s;
}

input:checked+.toggle-label {
    background-color: var(--color-1);
}

input:checked+.toggle-label::before {
    transform: translateX(20px);
}


/* --------- */
.toggle-switch-small {
    display: flex;
    position: relative;
    width: 50px;
    height: 30px;
    align-items: center;
}

.toggle-switch-small input {
    opacity: 0;
    width: 100%;
    height: 100%;
}

.toggle-label-small {
    position: absolute;
    background-color: var(--ex-clr);
    border-radius: 30px;
    height: 13px;
    width: 40px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggle-label-small::before {
    content: "";
    position: absolute;
    height: 21px;
    width: 21px;
    top: -4px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    left: 0;
}

input:checked+.toggle-label-small {
    background-color: var(--color-1);
}

input:checked+.toggle-label-small::before {
    transform: translateX(21px);
}

/* ------- */

.checkbox-column {
    width: 5px;
    padding: 0;
    text-align: center;
}


.selector{
    display: flex;
}
.options-table table{
    position: relative;
}
.options-table td {
    height: 46px;
}
.options-table th {
    
    position: sticky;
}
.tablescroll{
    height: 500px;
    overflow: auto;
}
.tabletotal{
    display: flex;
    padding: 16px;
    background: var(--bg-secondary);
    justify-content: space-between;
    font-weight: bold;
}