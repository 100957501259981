.toast-container {
  width: 332px;
  position: fixed;
  top: 0;
  right: 0;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  max-height: 100vh;
  overflow: auto;
  z-index: 9999999;
}

.ai-toast {
  display: flex;
  padding: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.30);
  border-radius: 8px;
  column-gap: 8px;
  background: var(--white);
  transition: transform 0.3s;
  &:first-child{margin-top:16px;}

  .close {
    color: var(--black);
    font-size: 24px;
    width: 24px;
    text-align: center;
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }

  &.warning {
    border: 2px solid var(--Light-Warning-border, #F7D9A4);
    background: var(--Light-Warning-background, #FFF8EC);
    box-shadow: 0px 4px 16px 0px rgba(16, 11, 39, 0.08);
  }

  &.error {
    border: 2px solid var(--Light-Error-border, #F4B0A1);
    background: var(--Light-Error-background, #FFF5F3);
    box-shadow: 0px 4px 16px 0px rgba(16, 11, 39, 0.08);
  }

  &.success {
    border: 2px solid var(--Light-Sucess-border, #48C1B5);
    background: var(--Light-Sucess-background, #F6FFF9);
    box-shadow: 0px 4px 16px 0px rgba(16, 11, 39, 0.08);
  }
  &.info {
    border: 1px solid var(--Light-Informative-border, #9DC0EE);
    background: var(--Light-Informative-background, #F5F9FF);
    box-shadow: 0px 4px 16px 0px rgba(16, 11, 39, 0.08);
  }

  .slide-in {
    transform: translateX(0);
  }

  .slide-out {
    transform: translateX(100%);
  }

  .toast-content {
    margin: 0;
    padding: 0;
    flex: 1;
    flex-direction: column;
    row-gap: 8px;
    justify-content: center;

    .toast-message {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .toast-image {
    width: 24px;
    height: 24px;

    .warning {
      color: var(--Orange);
    }

    .error {
      color: var(--Red);
    }

    .success {
      color: var(--Green);
    }

    img {
      width: 100%;
    }
  }

  .toast-content {
    display: flex;
  }
}


// popup css

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-object {
  position: relative;
  background: var(--white);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 460px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;

  p {
    word-wrap: break-word;
  }
}

.error .heading {
  background: var(--Error);
  color: var(--white);
}

.warning .heading {
  background: var(--Orange);
  color: var(--white);
}

.heading {
  width: 100%;
  background-color: var(--white);
  display: flex;
  align-items: center;
  list-style: none;
  left: 0;
  right: 0;
  top: 0;
  box-sizing: border-box;
  height: 50px;
  font-size: 16px;
  font-family: Inter;
  color: var(--f-navyblue);
  letter-spacing: 0.25px;
  justify-content: center;
}

.heading .icon {
  width: 24px;
  height: 24px;
  margin-bottom: -3px;
  margin-right: 8px;
}

.alertType {
  display: none;
}

.body-message {
  padding: 16px 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
}

.heading svg {
  fill: #fff;
}

.button-row {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 24px;
}