/* DashboardSection.module.css */
.dashboardSec {
    padding: 32px 64px;
    font-family: 'Inter', sans-serif;
  }
  
  .dashboardInner {
    /* Additional styles if needed */
  }
  
  .callHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dashboardTop {
    display: flex;
    flex-wrap: wrap;
    align-items: center; /* Align items vertically */
    padding-bottom: 24px;
    border-bottom: 1px solid #DFE4E9;
  }
  
  .dashList {
    display: flex;
    width: 100%; /* Fill the container */
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none; /* Removes bullet points */
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start */
    margin-bottom: 20px; /* Spacing between each form group */
  }
  
  .formGroup label {
    margin-bottom: 8px; /* Spacing between label and input/select */
  }
  
  /* Make all input fields, select boxes, and buttons the same width and height */
  .formGroup select,
  .formGroup input[type="text"],
  .formGroup input[type="date"],
  .buttonGroup button {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #DFE4E9;
    height: 40px; /* Example height */
    width: 150px; /* Set a fixed width for buttons */
    box-sizing: border-box; /* Ensures padding is included in width and height */
    cursor: pointer;
    transition: all .3s ease;
  }
  
  .buttonGroup {
    display: flex;
    gap: 10px;
    align-self: flex-start; /* Align button group to the start of the flex item */
  }
  
  .saveBtn, .cancelBtn {
    cursor: pointer;
    transition: all .3s ease;
  }
  
  .saveBtn {
    background-color: #60034C;
    color: white;
  }
  
  .cancelBtn {
    background-color: transparent;
    color: black;
    border: 1px solid #000;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .dashboardTop {
      flex-direction: column;
      align-items: stretch; /* Stretch items to fill the width */
    }
  
    .formGroup {
      margin-right: 0;
      margin-bottom: 10px;
      width: 100%; /* Full width on smaller screens */
    }
  
    .formGroup select,
    .formGroup input[type="text"],
    .formGroup input[type="date"],
    .buttonGroup button {
      width: calc(100% - 20px); /* Full width minus padding */
    }
  
    .buttonGroup {
      flex-direction: column;
      gap: 5px;
    }
  }
  
  /* Additional styles as needed */
  

.formGroupt p {
  padding: 10px;
  height: 40px; /* Example height */
  box-sizing: border-box; /* Ensures padding is included in width and height */
  background-color: #FFF; /* Set the background color */
  margin: 0; /* Reset default margin of p */
  line-height: 20px; /* Adjust line height as needed */
}