.loginwrapper {
  height: 100vh;
  width: 100%;
  display: flex;

  .ai-slide-content {
    width: 30%;
    background: var(--border);
    padding: 64px;

    .slick-dots {
      li {
        width: auto;
        height: auto;

        button {
          width: auto;
          height: auto;

          &:before {
            content: "";
            width: 10px;
            height: 10px;
            background: var(--black);
            border-radius: 10px;
            transition: all 0.2s ease-in-out;
            position: static;
            display: block;
          }
        }

        &.slick-active {
          button {
            &:before {
              width: 30px;
              background: var(--b-secondary);
            }
          }
        }
      }
    }

  }

  .ai-login-container {
    width: 70%;
    background: var(--white);
    display: flex;
    // padding: 0 48px;
    flex-direction: column;

    h2 {
      margin: 0;
      font-size: 20px;
      color: var(--b-secondary);
      font-weight: 600;
      text-align: left;
      width: 100%;
    }

    footer {
      padding: 16px;
      text-align: center;
    }

    .ai-login-form {
      display: flex;
      width: 100%;
      flex: 1;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .ai-login-section {
        max-width: 100%;
        width: 450px;
        display: flex;
        align-items: center;
        padding: 24px;

        form {
          width: 100%;
        }

        .backtouser {
          margin-right: 24px;
        }
      }

      .b-logo {
        max-width: 240px;
        margin-bottom: 64px;
      }
    }

    .usertab {
      display: flex;
      padding-top: 24px;

      a {
        display: flex;
        width: 50%;
        align-items: center;
        column-gap: 16px;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 8px;
        border: 2px var(--white) solid;
        transition: all 0.2s ease-in-out;

        span {
          flex: 1;
        }

        &:hover {
          border: 2px var(--b-secondary) solid;
        }
      }
    }
  }

  .ai-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
    display: block;
  }

  .slick-slide {
    >div {
      height: 100%;

      .ai-slide {
        height: 100%;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        text-align: center;

        p {
          color: var(--b-grey);
          font-size: 18px;
          line-height: 30px;

          b {
            color: var(--black);
          }
        }
      }

      .ai-slide-image {
        text-align: center;

        img {
          display: inline-block;
        }
      }
    }
  }

  .ai-signin {
    display: flex;
    flex-direction: column;
    color: var(--f-navyblue);

    span {
      line-height: 28px;
    }

    p {
      margin: 0 0 6px 0;
    }

    &.middle-text {
      width: 100%;
      align-items: center;
    }
  }

  .calendlysaprator {
    border-bottom: 1px var(--Black) solid;
    text-align: center;
    position: relative;
    margin: 32px 0;

    span {
      z-index: 2;
      position: absolute;
      display: flex;
      background: var(--b-secondary);
      color: var(--b-primary);
      height: 32px;
      width: 32px;
      line-height: 32px;
      top: -16px;
      left: 50%;
      margin-left: -16px;
      border-radius: 100%;
      font-size: 12px;
      justify-content: center;
      font-weight: 600;
    }

    &:after {
      content: "";
      z-index: 1;
      position: absolute;
      display: inline-block;
      background: var(--white);
      height: 32px;
      width: 64px;
      top: -16px;
      left: 50%;
      margin-left: -32px;
      font-size: 14px;
    }
  }

  .accounticon {
    width: 32px;
    height: 32px;
  }

}

@media (max-width:992px) {
  .loginwrapper .ai-slide-content {
    display: none;
  }

  .loginwrapper .ai-login-container {
    width: 100%;
  }

}

.LoginScreenBody {
  overflow-y: hidden;
}

.LeftDiv {
  background-color: white;
  width: 596px;
  height: 1022px;
}

.LeftDiv-2 {
  background-color: white;
  width: 456px;
  height: 1022px;
}

.LeftDiv-3 {
  margin-top: 20px;
  text-align: center;
}

.LeftDiv-4 {
  margin-top: 20px;
  text-align: center;
}

.RightDiv {
  margin-left: 596px;
  height: 750px;
  margin-top: -1000px;
}

.RightDiv-2 {
  margin-left: 456px;
  height: 750px;
  margin-top: -1000px;
}

.RightDiv-3 {
  margin: auto;
  height: 750px;
  min-width: 300px;
  max-width: 700px;
  align-items: center;
}

.loginWindow {
  margin-top: 200px;
  margin-left: 90px;
}

.loginWindow-2 {
  margin-top: 200px;
  margin-left: 50px;
}

.loginWindow-3 {
  margin-top: 40px;
}

.loginWindow-4 {
  margin-top: 40px;
}

.login-logo {
  margin-left: 57px;
  margin-top: 57px;
}

.messageBox {
  margin: auto;
  width: 400px;
  margin-top: 108px;
}

.fineText {
  margin-top: 50px;
  margin-left: 25px;
  margin-right: 25px;
  float: bottom;
}

.fineText-2 {
  /* margin-top: 70px; */
  margin-left: 25px;
  margin-right: 25px;
  float: bottom;
}

.fineText-3 {
  margin: auto;
  margin-top: 70px;
}

.fineText-4 {
  margin: auto;
  margin-top: 70px;
}

.notes {
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 1px;
  color: #60034c;
}

.disclaimer {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: var(--f-navyblue);
}

.tnc_privacy {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #60034c;
}
@media (max-width: 992px) {
  .loginwrapper .ai-login-container .ai-login-form{
    height: fit-content;
    flex:auto;
    min-height: calc(100vh - 200px);
  }
  
  .loginwrapper{height: auto;}
  .loginwrapper .ai-login-container .ai-login-form .b-logo{
    margin: 32px;
  }
}