.portfolio-screen {
    font-family: Arial, sans-serif;
    width: 500px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.inputs div {
    flex: 1;
    margin-right: 10px;
}

.inputs label {
    display: block;
    margin-bottom: 10px;
}

.inputs input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.portfolio-list {
    list-style: none;
    padding: 0;
    width:100%;
}

.portfolio-list li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
}
.portfoliodata{
    max-width: 600px;
    width: 100%;
}

