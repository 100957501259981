
.shieldrecc{
    min-width: 100%;
    &.two{
        display: flex;
        .split{
            width:50%;
            .header-card{
                width:50%;
            }
            .card.small{
                width:50%;
            }
        }
    }
    .cardscontainer{
        flex: 1;
        display: flex;
        overflow: auto;
    }
}
.left-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* Spacing between labels */
}
.switchcontent{
    display: flex;
    column-gap: 16px;
    align-items: center;
    margin-right: 24px;
    font-size: 14px;
}
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 12px;
}

.toggle-switch input {
    display: block;
    position: absolute;
    z-index: 2;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #DFE4E9;
    transition: .4s;
    border-radius: 10px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 0;
    top: -3px;
    background-color: var(--b-secondary);
    transition: .4s;
    border-radius: 20px;
}

input:checked+.slider {
    background-color: var(--Grey);
}

input:checked+.slider:before {
    transform: translateX(18px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.right-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.portfolio-info h1 {
    font-size: 16px;
    color: #333;
}

.tabs-container {
    display: flex;
    gap: 20px;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
}
.sumbtn{
    position: absolute;
    top: -40px;
    right: -2px;
    display: none;
    background: var(--Grey);
    z-index: 999;
    border-radius:4px 4px 0 0;
    overflow: hidden;
    .ai-btn{
        padding:12px 8px !important;
        border-radius: 0 !important;
        &.pdf{
            background: #FF2116;
        }
        &.image{
            background: var(--Blue);
        }
    }
    img{
        min-width: 24px;
        min-height: 24px;
    }
}
.sum-container {
    position: relative;
    transition: all 0.2s ease-in-out;
    border-radius: 4px 0 4px 4px;
    border: 2px transparent solid;
    &:hover{
        border: 2px #FF2116 solid !important;
        background: var(--white);
        > .sumbtn{
            display: flex;
        }
    }
}

.card {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    font-weight: 500;
    color:var(--black);
    text-align: center;
    width: 33.33%;
    min-width: 250px;
    box-sizing: border-box;
    overflow: hidden;
    &.small{
        width: 200px;
        min-width: 200px;
    }
    h4,h3{
        margin: 0;
    }
    h3{
        font-size: 14px;
        color: var(--b-secondary);
    }
    > div {
        border-bottom: 1px solid var(--border);
        height:40px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        &:last-child{
            border-bottom: none;
        }
    }
}


.card:nth-child(even) {
    background-color: #f6f6f6;
}

.card:nth-child(odd) {
    background-color: #ffffff;
}

// .card:last-child {
//     background-color: #f6f6f6;
//     opacity: 0.5;
//     // pointer-events: none;
// }


.header-card {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding:  24px 24px 24px 0;
    font-weight: bold;
    color: #8A92A6;
    text-align: right;
    width: 25%;
    min-width: 250px;
    max-width: 340px;
    box-sizing: border-box;
    overflow: hidden;
    // justify-content: center;
    text-transform: uppercase;
    > div {
        border-bottom: 1px solid var(--border);
        height:40px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        font-weight: normal;
        font-size: 14px;
        text-transform: capitalize;
    }

}

.header-card div:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.center-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.right-button-container {
    display: flex;
    justify-content: right;
    align-items: right;
    margin: 20px 0;
}

/* Space between cards and proper alignment */
.cards-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 32px;
    width: 100%;
}


.shopping-card {
    flex: 1;
    min-width: 250px;
    padding: 16px 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    background: var(--white);
    border-bottom: 1px var(--d-border) solid;
    @media (max-width: 768px) {
        padding: 16px;
    }
    h4{
        cursor: pointer;
        width: 100%;
        color: var(--b-primary);
        display: flex;
        justify-content: space-between;
        background: var(--bg-grey);
        padding: 8px;
        border-radius: 8px;
    }
}

/* Item row styling */
.shopping-card > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.shopping-card > div span:first-child{
    width: 40%;
}
.shopping-card > div span{
    width: 15%;
}
.shopping-card span {
    overflow: hidden;
    text-overflow: ellipsis;
}
.shopping-card > div span.premium{
    text-align: right;
}
.shopping-card > div.premium.totalprice{
    justify-content: flex-end;
    color: var(--b-primary);
    font-weight: 600;
}
.shoppinghead{
    display: flex;
    justify-content: space-between;
}
/* New class for aligning quantity and color adjustment */
.quantity, .premium {
    color: var(--b-secondary); /* Change color */
}

/* Align quantity symbol */
.quantity-symbol {
    margin: 0 5px; /* Adjust spacing around the 'x' symbol */
}


// .cards-group {
//     display: flex;
//     justify-content: space-between; /* Adjust to manage spacing between cards */
//     flex-wrap: wrap; /* Allow wrapping to next line on smaller screens */
//     gap: 32px; /* Space between cards */
// }

.shopping-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the cards within the container */
    flex-wrap: wrap;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
}

// .shopping-card {
//     flex: 1; /* Allows the card to grow to fill the space */
//     min-width: 250px; /* Minimum width before cards stack vertically */
//     max-width: 100%; /* Maximum width to allow two cards side by side */
//     padding: 60px;
//     box-sizing: border-box;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: flex-start;
//     gap: 16px;
//     background: var(--white);
//     box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
//     overflow-y: auto; /* Keep for scrollability if needed */
// }

// /* Ensure contract details fit in one line */
// .shopping-card > div {
//     display: flex;
//     width: 100%;
//     justify-content: space-between; /* Space out content */
//     flex-wrap: nowrap; /* Prevent wrapping */
// }

// .shopping-card span {
    
//     overflow: hidden;
//     text-overflow: ellipsis; /* Use ellipsis for overflow */
// }


.shopping-cart {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media (max-width: 768px) {
        padding: 16px;
    }
}

// .shopping-card-container {
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     column-gap: 32px;
//     transition: box-shadow 0.3s ease-in-out;  
//     margin-right: 20px;
// }

// .shopping-card {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     padding:32px 64px;
//     box-sizing: border-box;
//     box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
//     row-gap: 16px;
//     background: var(--white);
//     >div{
//         display: flex;
//         width: 100%;
//         column-gap: 8px;
//         color: var(--b-secondary);
//         span{
//             color: var(--black);
//             &:first-child{
//             display: flex;
//             flex: 1;
//         }}
//     }
// }


.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.title,
.price {
    font-weight: bold;
}

// .dropdown {
//     padding: 8px;
//     width: 100%;
// }

.dropdown {
    padding: 8px;
    border: none;
    font-size: 15px;
    border-bottom: 1px solid #000;
    outline: none;
    background: transparent;
}


.protection {
    margin: 10px 0;
}

@media (max-width: 768px) {
    .card {
        flex-basis: 90%;
    }
}

@media (max-width: 480px) {
    .card {
        flex-basis: 100%;
    }
}
.portfolioname{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-bottom: 16px;
    p{
        font-size: 14px;
        margin: 0;
    }
}
.tickercon{
    display: flex;
    column-gap: 32px;
    align-items: flex-end;
    .tickerlist{
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        button{
            background: none;
        }
    }
}
.slidegroup{
    // border: 1px var(--d-border) solid;
    border-radius: 4px;
    display: flex;
    flex: 1;
    gap: 16px;
    .slidecontrol{
        display: flex;
        gap: 32px;
        flex: 1;
        align-items: center;
        padding: 4px;
        .ai-form-value-bt{
            margin-top: 0px !important;
            display: inline-flex;
            width: 200px !important;
            height: 36px;
            background: none !important;
            border: 1px var(--d-border) solid !important;
        }
        .MuiBox-root{
            width: 100%;
            padding: 0 16px;
        }
    }
}
.slider-container {
    width: 100%;
    background-color: var(--bg-grey);
    display: flex;
    align-items: center;
    &.small{
        padding: 8px;
        background: none;
        width: auto;
        gap: 8px;
        border-right: 1px var(--d-border) solid !important;
        .pill{
            width: auto;
            height: 100%;
            padding: 8px 16px;
            background: var(--border);
        }
    }
    .pill{
        // font-size: 10px;
        padding: 8px;
        color: var(--black);
        border-radius: 4px;
        width: 50%;
        cursor: pointer;
        align-items: center;
        display: flex;
        justify-content: center;
        &.active{
            background: var(--Secondary);
            color:var(--white) ;
        }
    }
}
.react-datepicker-wrapper {
    width: 100%;
    input {
    min-width: 100%;
    font-size: 14px !important;
    line-height: 20px !important;
    padding: 8px 16px !important;
    border: none !important;
    margin-top: 10px;
  }
  svg{
    right: 10px !important;
    top: 58% !important;
  }
}
.summaryarea{
    display: flex;
    margin-top: 30px;
    .sumstates{
        width: 66.66%;
        display: flex;
        flex-wrap: wrap;
        border-top: 1px var(--border) solid;
        .sumvalue{
            width: 50%;
            display: flex;
            padding: 16px 32px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            border: 1px var(--border) solid;
            border-top:none;
            label{
                color: var(--b-secondary);
                font-size: 18px;
                font-weight: 700;
                line-height: 20px; /* 111.111% */
                letter-spacing: 1px;
                text-transform: uppercase;
            }
            div{
                font-size: 32px;
                font-weight: 500;
                text-transform: uppercase;
            }
            &:nth-child(2n + 1){
                border-right:none;
            }
        }
    }
    .pricearea{
        background: var(--border);
        width: 33.33%;
        display: flex;
        padding: 0px 32px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            text-transform: capitalize;
            &.totalprice{
                color: var(--b-primary);
                font-size: 18px;
                font-weight: 700;
            }
        }
    }
}
.assettable{
    margin-top: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    h2{
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-align: left;
    }
}

.custom-slider-container {
    position: relative;
    width: 95%;
}

  .custom-slider {
    -webkit-appearance: none; /* Remove default appearance */
    appearance: none;
    width: 100%;
    margin: 10px 0;
    margin-left: 17px;
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    background: #ddd;
    border-radius: 5px;
    outline: none;
  }

  .custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: var(--b-primary);
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid var(--b-primary);
  }
  
  .custom-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: var(--b-primary);
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid var(--b-primary);
  }

.movable-tooltip {
    position: absolute;
    top: -65px; /* Adjust to position above the slider */
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    transition: left 0.1s ease; /* Smooth transition */
}

.tooltip-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tick {
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
}

.asset-allocation-ticks {
    position: absolute;
    top: -20px; /* Adds more space above the slider */
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 20px;
}

.asset-allocation-ticks .tick-label {
    // background-color: #7c8dba;
    // color: #fff;
    padding: 4px 6px;
    border-radius: 4px;
    min-width: 30px;
    text-align: center;
    white-space: nowrap;  /* Prevents text wrapping */
    overflow: hidden;
    text-overflow: ellipsis;  /* Adds ellipsis for overflowed text */
    margin-top: -10px; 
}

.std-dev-ticks {
    position: absolute;
    bottom: -40px; /* Middle layer for standard deviation ticks */
    width: 100%;
    // background-color: #f0f0f0;
    border-radius: 3px;
    color: #333;
    margin-left: 20px;
}

.protection-level-ticks {
    position: absolute;
    background-color: #f0f0f0;
    border-radius: 3px;
    color: #333;
    bottom: -17px; /* Bottom layer for protection level ticks */
    width: 100%;
    margin-left: 15px;
    
}
.slider-tick-row{
    position: absolute;
    top: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 20px;
}

.downvol-tick-row {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.downvol-tick-row {
    bottom: -20px; /* Place below the asset allocation ticks */
}

.tick-label {
    // overflow: hidden;
    text-overflow: ellipsis;
    // max-width: 35px;
    // background-color: #f0f0f0;
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 12px;
    color: #333;
}

.protection-label {
    font-size: 14px;  /* Match the other headers */
    // font-weight: bold;
    text-align: left;
    width: 60px;  /* Adjust to limit width and create line breaks */
    // word-break: break-all;  /* Force line breaks between words */
    line-height: 1.2;  /* Adjust line height for readability */
    margin-right: 50px;
    display: flex;
    color: var(--b-primary);
    align-items: center;  /* Align text to the left */
    gap: 4px;  /* Space between the words */
}

.arrow-right {
    font-size: 18px;
    margin-top: 8px;  
    margin-left: 2px; 
}


.slidegroup .label-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    align-items: flex-end;
    font-weight: 500;
    margin-left: 20px;
}

.label-container .label {
    text-align: right;
    font-size: 12px; 
    color: var(--b-secondary); 
    flex: 1 ; 
    margin-top: -20px 
}

.label .label-asset {
    position: absolute;
    transform: translateX(-50%);
    font-size: 12px;
    color: var(--b-secondary);
    // margin-top: -30px !important;
}


.label-floor {
    position: absolute;
    font-size: 12px;
    color: var(--b-secondary);
    margin-top: 50px !important;
}
.label-std {
    position: absolute;
    font-size: 12px;
    color: var(--b-secondary);
    margin-top: 70px !important;
}

.assettable table tbody tr {
    font-size: 15px !important;
    font-weight: 500; 
}
/* Container for the toggle */
.custom-toggle-container {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    cursor: pointer;
}

/* Hide the checkbox, but keep it functional */
#custom-asset-mode-toggle {
    position: absolute;
    opacity: 0;
    pointer-events: all;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
}

/* Custom toggle label */
.custom-toggle-label {
    display: inline-block;
    width: 60px;
    height: 30px;
    background-color: var(--b-secondary);
    border-radius: 30px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Knob inside the toggle */
.custom-toggle-label::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

/* Active state for the toggle */
#custom-asset-mode-toggle:checked + .custom-toggle-label {
    background-color:var(--b-primary);
}

/* Move the knob to the right when checked */
#custom-asset-mode-toggle:checked + .custom-toggle-label::after {
    transform: translateX(30px);
}


.optimizer-beta-v {
    display: inline-block;
    padding: 2px 8px;
    color: white;
    background-color: var(--b-secondary);
    line-height: 1;
    border-radius: 4px;
    margin-left: 8px;
    font-size: 12px;
    line-height: 12px;
    position: relative;
    cursor: pointer;
  }
  
.optimizer-beta-v:hover::after {
    content: attr(title);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 12px;
    margin-top: 4px;
    z-index: 1;
    pointer-events: none;
  }

  .optimizer-btn {
    padding: 12px 20px;
    font-size: 15px;
    font-weight: 600;
}