.onboard_wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  .boardlogo{
    img{
      height: 60px;
    }
  }
  .welcome{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    width: 100%;
    flex: 1;
    padding: 24px 0;
    .welcomecon{
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      row-gap: 24px;
      justify-content: center;
      width: 100%;
    }
    img{
      width: 86px;
      height: 86px;
    }
    h1{
      margin: 0 0 32px;
    }
    p{
      margin: 0;
    }
  }
  .onboardcards{
    display: flex;
    column-gap: 48px;
    a{
      border: 1px var(--border) solid;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      padding: 48px;
      align-items: center;
      text-decoration: none;
      color: var(--black);
      font-weight: 600;
      font-size: 16px;
      row-gap: 16px;
      .icon{
        padding: 16px;
        background: var(--bg-primary);
        display: inline-block;
        width: 64px;
        height: 64px;
        border-radius: 8px;
        
        img{
          width: 32px;
          height: 32px;
        }
      }
    }
    .plan{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      color: var(--black);
      font-weight: 600;
      font-size: 16px;
      row-gap: 16px;
      row-gap: 32px;
      &.standard{
        .icon{background: var(--b-secondary);}
        li:nth-child(2n){
          background: var(--bg-secondary);
        }
        .price{color:var(--b-secondary);}
      }
      &.advisor{
        .icon{background: var(--b-primary);}
        li:nth-child(2n){
          background: var(--bg-primary);
        }
        .price{color:var(--b-primary);}
      }
      .head{
        display: flex;
        width: 100%;
        column-gap: 32px;
        align-items: center;
        .planname{
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          h3{
            font-size: 32px;
            font-weight: 400;
            margin: 0;
          }
          .price{
            font-size: 20px;
          }
        }        
      }
      .icon{
        padding: 8px;
        background: var(--bg-primary);
        display: inline-block;
        border-radius: 8px;
        img{
          width: 64px;
          height: 64px;
        }
      }
      ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
          display: flex;
          font-weight: 500;
          align-items: center;
          column-gap: 10px;
          padding: 16px;
          img{
          width: 24px;
          height: 24px;
          }
        }
      }
    }
  }
}
.title{
    display: block;
    text-align: center;
    color: var(--b-primary);
    font-family: "Inter";
    font-weight: 800;
    font-size: 32px;
    position: relative;
}

.onboarding {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    padding: 32px;
    width: 100%;
    max-width: 800px;
    // column-gap: space-beetween;
    justify-content: space-between;

    .onboardstep{  
      width: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.active{
        .icon {
          border: 1px var(--b-secondary) dashed;  
          svg{
            path{
              stroke: var(--b-primary);  
            }
          }
        }
      }
      .icon {
        padding: 16px;
        border: 1px var(--b-grey) dashed;
        border-radius: 100%;
        width: 64px;
        height: 64px;
      }
    }
    
}



.main-content{
    justify-content: center;
    align-items: center;
    position: relative;
    height: calc(100vh - 65px);
    overflow: auto;
    box-sizing: border-box;
    padding:24px 48px;
    @media  (max-width:768px){
      padding:24px; 
    }
}


.button-div {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 32px;
    margin-top: 8px;
    padding-bottom: 1.75%;
    background: none;
}
// Button css
.listbtn {
  display: flex;
  column-gap: 16px;
  flex: 1;
  justify-content: flex-end;
}

.porttop {
  display: flex;
  padding-bottom: 16px;
  flex-wrap: wrap;
  row-gap: 16px;
}

.dropdown {
  position: relative;
  z-index: 2;

  &:hover .dropoption {
    display: block;
  }

  .dropoption {
    padding: 8px 16px;
    position: absolute;
    top: 100%;
    right: 0;
    display: none;
    background: var(--white);
    box-shadow: 0 8px 28px rgba(24, 39, 75, 0.12), 0 18px 88px rgba(24, 39, 75, 0.14);
    list-style: none;
    width: 250px;
    margin: 0;
    border-radius: 4px;

    li {
      padding: 14px 0;
      border-bottom: 1px rgba(14, 27, 66, 0.07) solid;
      white-space: nowrap;

      &:last-child {
        border-bottom: none;
      }

      button {
        background: none;
        width: 100%;
        border: none;
        display: flex;
        align-content: center;
        align-items: center;
        column-gap: 24px;
        cursor: pointer;
      }
    }
  }
}


.card_title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.91px;
  text-align: center;
  color: var(--b-primary);
  row-gap: 48px;
  column-gap: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px;
  border-color: var(--Grey);
  border-style: solid;
  border-radius: 20px;
}
.card_title img {
  height: 150px;
  width: auto;
  display: inline-block;
  padding: 5px;
  align-content: center;
  align-items: center;
  margin: auto;
}

.title_text {
  margin: 5px;
  padding: 10px;
  align-content: center;
  align-items: center; 
}

.welcomecon{
  .portfoliocard{
    flex-direction: column;
    h2{
      display: flex;
      column-gap: 16px;
    }
    .portlist{
      width: 100% !important;
    }
  }
}
.asset-btn{
  display: flex;
  padding: 8px 0;
  column-gap: 24px;
  button{
    border: none;
    background: none;
    padding: none;
    line-height: 24px;
    color: var(--b-primary);
    font-weight: bold;
  }
}
.importtable{
  width: 100%;
  max-width:1024px;
}
.modalcon{
  max-width: 1400px;
  width: 100%;
  padding: 40px;
  flex: 1;
}
.ai-form-value-bt{
  background: #F1F1F1 !important;
    margin-top: 10px !important;
    padding: 0 !important;
    border-radius: 4px !important;
    border: none !important;
    color: var(--Black) !important;
    width: 100% !important;
    box-sizing: border-box !important;
    .MuiSelect-select, .react-datepicker-wrapper input {
      font-size: 14px !important;
      line-height: 20px !important;
      padding: 8px 16px !important;
    }

}