/* Spinner.css */
.spinner {
    width: 16px;
    height: 16px;
    border: 2px solid var(--white); /* Light grey */
    border-top: 2px solid transparent; /* Blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    &.dark{
      border: 2px solid var(--b-primary);
      border-top: 2px solid transparent; 
    }
    &.big{
      width: 32px;
      height: 32px;
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  