.riskchart {
    margin-top: 20px;
    max-height: 500px;
    position: relative;
    padding: 0 24px;
}

.riskmodes {
    padding: 0;
}

.m0i {
    margin: 0 !important;
}

.chartlbl {
    position: absolute;
    top: 50%;
    transform: rotate(-90deg);
    width: 160px;
    height: 16px;
    text-align: center;
    color: var(--b-primary);
    font-weight: 600;
    margin-top: -40px;

    &.left {
        left: -75px;
    }

    &.right {
        right: -80px;
        width: 190px;
    }
}
.pdfDownloadDiv{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: right;
    width: 1400px;
}

.GraphDiv{
    width: 100%;
    position: relative;
    padding: 0;

}

