.sidebar {
    background-color: #fff;
    width: 300px;
    display: flex;
    position: relative;
    z-index: 99;
    flex-direction: column;
    height: calc(100vh - 65px);
    // box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    border-right: 1px var(--border) solid;
    .clientlist{
        display: flex;
        overflow: auto;
        flex-direction: column;
        padding: 16px;
        flex: 1;
        .clientcard{
            display: flex;
            flex-direction: column;
            .clientdata, .portfoliodata{
                color: var(--b-grey);   
                font-size: 14px;
                font-weight: 500;
                margin: 0;
                padding:4px 0;
                display: flex;
                align-items: center;
                column-gap: 12px;
                box-sizing: border-box;
                .name{
                    flex: 1;
                    &.active{
                        color: var(--Secondary);
                        font-weight: 600;
                    };
                    &.portfoliopadding{
                        margin-left: 16px;
                    }
                };
            }
            .portfoliodata{                
                padding:8px 0;
                cursor: pointer;
                input{
                    width:auto
                }
            }
            button{
                background: none;
                border: none;
                padding:0;
            }
        }       
    }
}

.tabs {
    display: flex;
    margin-bottom: 20px;
}

.tabs span {
    flex: 1;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.active-tab {
    background-color: #ddd;
}

.client-list {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 20px;
    flex: 1;
}

.client-item,
.portfolio-item {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.client-item input[type="checkbox"],
.portfolio-item input[type="checkbox"] {
    border: none;
    outline: none;
}

.expand-icon {
    margin-left: 10px;
    cursor: pointer;
    transition: transform 0.3s;
}

.expand-icon.expanded {
    transform: rotate(180deg);
}

.portfolio-item {
    margin-left: 20px;
}

.options-icon {
    margin-left: auto;
    cursor: pointer;
}

.ai-button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #333;
    color: white;
    cursor: pointer;
    transition: background 0.3s;
    margin: 5px 0;
    width: 100%;
}

.ai-button:hover {
    background-color: #555;
}

.sidebar-buttons {
    padding: 16px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .ai-btn{
        width: 100%;
    }
}

.modal {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(240, 237, 237, 0.5); /* Semi-transparent background */
}

.portfolio-modal {
    position: absolute;
    background-color: #fff;
    padding: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    transition: transform 0.3s;
    transform-origin: top left;
    width: 250px;
    right: 0;
    button {
        display: block;
        width: 100%;
        border: none;
        background: none;        
        text-align: left;
        padding: 16px ;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: var(--black);
        span{
            flex: 1;
        }
    }
}

.portfolio-modal button:hover {
    background-color:var(--bg-grey);
}
