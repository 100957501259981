.round {
    display: flex;
    flex-direction: row;
    row-gap: 48px;
    column-gap: 48px;
    justify-content: center;
    margin-top: 10px;
}
.round_card_title {
    color: var(--b-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative; 
    width: 150px; 
    height: 150px; 
    overflow: hidden; 
    border-radius: 50%;
    border: 1px;
    border-color: var(--b-primary);
    border-style: dashed;
}
.round_card_title img {
    align-content: center;
    align-items: center;
    height: fit-content;
    width: fit-content;
    margin-top: 30px;
}  
.round_title_text {
    align-content: center;
    align-items: center; 
    text-align: center;
    margin: 0;
}

.upload-section {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #E6E8F0;
    border-radius: 4px;
    position: relative;
    font-size: 16px;
    column-gap: 16px;
    margin-top: 32px;
    height: calc(100% - 100px);
    min-height: 300px;
}

.upload-section img {
    max-height: 100%;
}

.upload-section:hover {
    background-color: #f5f5f5;
}

.upload-section,
.portfoliodata {
    width: 100%;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.full-label {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    column-gap: 16px;
    padding: 8px;
}


.toogle-switch{
    display: flex;
    flex-direction: row;
    row-gap: 24px;
    column-gap: 24px;
}