@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Bold.woff2") format("woff2"),
    url("./fonts/Inter-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-SemiBold.woff2") format("woff2"),
    url("./fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Medium.woff2") format("woff2"),
    url("./fonts/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Regular.woff2") format("woff2"),
    url("./fonts/Inter-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Droid_Mono';
  src: url('./fonts/Droid Sans Mono.woff2') format('woff2'),
    url('./fonts/Droid Sans Mono.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}