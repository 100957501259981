.factoranalysis {
    justify-content: flex-start;
}

.factorexposure {
    display: flex;
    width: 100%;
    column-gap: 32px;
    row-gap: 32px;
    flex-wrap: wrap;

    .contributio-table {
        width: calc(50% - 32px);
        padding: 0;
        border-radius: 8px !important;
        box-shadow: 0 4px 14px rgba(24, 39, 75, 0.12), 0 9px 44px rgba(24, 39, 75, 0.14);
        @media (max-width:992px) {
            width: 100%;
        }

        .table_holder {
            margin: 0;
            flex: 1;

            h3 {
                font-size: 16px;
            }
            
        }
    }

    .portriskgrid {
        width: 50%;
        border-radius: 8px;
        box-shadow: 0 4px 14px rgba(24, 39, 75, 0.12), 0 9px 44px rgba(24, 39, 75, 0.14);

        @media (max-width:992px) {
            width: 100%;
        }


        h3 {
            background: var(--bg-grey);
            margin: 0;
            padding:12px 16px;
            color: var(--Navy-Blue);
            cursor: pointer;
            font-size: 14px;
            line-height: 16px;
            border-radius: 4px 4px 0 0;
            span {
                float: right;
                padding: 3px 8px;
                font-size: 24px;
                margin: -2px 0;
                font-weight: normal;
            }
        }

        .errorgrid {
            display: flex;
            flex-wrap: wrap;
            padding: 24px;
            border: 1px var(--bg-gray) solid;
            column-gap: 32px;
            row-gap: 32px;
            justify-content: center;
            border-radius: 0 0 4px 4px;
            .trackingerror { 
                text-align: center;

                h3 {
                    background: none;
                    margin-bottom: 8px;
                }

                h4 {
                    font-size: 14px;
                    color: var(--Black);
                    margin: 0;
                    text-align: left;
                    width: 120px;
                }

                p {
                    font-size: 22px;
                    color: var(--b-primary);
                    font-weight: 600;
                    margin: 0;
                    padding-bottom: 8px;
                    border-bottom: 1px var(--bg-primary) solid;
                }

                h5 {
                    font-size: 14px;
                    color: var(--b-primary);
                    margin-top: 8px;
                }

                .tag {
                    color: var(--white);
                    padding: 4px;
                    border-radius: 4px;
                    display: inline-block;
                    width: 60px;
                    text-align: center;
                    font-size: 14px;

                    &.primary {
                        background: var(--b-primary);
                    }

                    &.secondary {
                        background: var(--b-secondary);
                    }

                    &.gray {
                        background: var(--Grey);
                    }
                }

                .errolist {
                    display: flex;
                    flex-direction: column;
                    row-gap: 8px;
                    margin-top: 16px;

                    .tagspan {
                        display: flex;
                    }
                }
            }
        }
    }
}

.nogap {
    .table_holder {
        margin: 0;
    }
}

.factoranalysis {
    .factortbl {
        flex: none;
        margin-top: 32px;

        .subnavbar-menu {
            flex: none;
            max-height: 60px;
        }

        .table_holder {
            flex: none;
            height: auto;
        }
    }
}