.summary-content{
    width: 100%;
    background: var(--bg-grey);
    padding:24px 100px;
    display: flex;
}

.summarynote{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background: var(--bg-grey);
    margin-top: 16px;
    border-radius: 8px;
    textarea{
        border-radius: 4px;
        padding: 8px;
        width: 100%;
        height: 100px;
    }
 .summaryaction{
    display: flex;
    gap: 16px;
 }   
}
.containertop{
    position: absolute;
    top: 0;
    padding: 8px 24px;
    width: 100%;
    left: 0;
    background: var(--bg-grey);
}
.dragcontent{
    position: relative;
    border: 1px var(--border) solid !important;
    padding: 8px;
    border-radius: 4px;
    &.hidden{
        &::after{
            content: "Hidden";
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            background: rgba(255,255,255,0.8);
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            border: 1px var(--Grey) solid !important;
            font-size: 20px;
        }
    }
    .block-actions{
        display: none;
        position: absolute;
        top: -42px;
        left: 50%;
        transform: translateX(-50%);
        background: var(--white);
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        .ai-btn{
            padding:12px 8px;
            border-radius: 0;

        }
    }
    &:hover{
        background: var(--white);
        box-shadow: 0 4px 14px rgba(24, 39, 75, 0.12), 0 9px 44px rgba(24, 39, 75, 0.14);
        .block-actions{
            display: flex;
        }
        border: 1px var(--b-grey) solid !important;
    }
}
.pdffile{
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}
.blocknote{
    position: relative;
    p{  
        padding: 16px 48px 16px 16px;
        border-radius: 8px;
        background: var(--bg-grey);
        margin: 0;
    }
    .noteedit{
        position: absolute;
        right: 5px;
        top: 5px;
        gap: 5px;
        display: flex;
        .ai-btn{
            padding:8px 8px;
        }
    }    
}
.addnote{
    width: 100%;
    border: none;
    background: var(--bg-grey);
    padding: 16px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 4px;
    font-size: 16px;
    display: flex;
    margin-top: 8px;
}
.tag-item{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.title-div{
    width: 100%;
    bottom: 10px;
}