.ai-otp-screen p {
  margin: 0 0 6px 0;
  color: #666666;
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.1px;
}

.otp-field {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  width: 100%;
}

.otp-form-value {
  display: flex;
    background: #f1f1f1;
    margin-top: 10px;
    padding: 16px;
    border-radius: 4px;
    border: none;
    color: var(--Black);
    box-sizing: border-box;
    width: 16.66%;
}

.otp-form-value::placeholder {
  color: var(--inactive-text-field-color);
}

.otp-form-value:focus::placeholder {
  color: var(--form-text-color);
}

.otp-form-value:focus {
  outline: none;
  border: 1px solid var(--b-primary);
  color: var(--Black);
}

@media (max-width: 480px) {
  .otp-field {
    column-gap: 4px;
  }
}